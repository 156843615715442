<template>
  <div class="body">
    <el-tabs v-model="activeTab" type="card" @tab-click="tabClick">
      <el-tab-pane :label="$t('elevator.baseInfo')" name="first">
        <div class="vm-separate">
          <span v-if="$i18n.isCn">{{$t("elevator.elevatorType")}}：{{elevator.elevatorProductTypeName}}</span>
          <span v-else>{{$t("elevator.elevatorType")}}：{{elevator.elevatorProductTypeNameEn}}</span>
          <span>{{$t("elevator.name")}}：{{elevator.name}}</span>
          <span>{{$t("elevator.model")}}：{{elevator.model}}</span>
        </div>

        <div class="vm-separate">
          <span>设备代码：{{elevator.deviceId}}</span>
        </div>

        <div class="vm-separate">
          <span>{{$l("elevator.registerCode", "注册代码")}}：{{elevator.regNo}}&nbsp;|&nbsp;
            <router-link :to="'/singleMonitor/' + elevator.id" target="_blank">
              <span style="color: #409EFF">
                {{$t("workOrder.monitorElevator")}}
              </span>
            </router-link>
          </span>
          <span>{{$t("elevator.exFactoryNo")}}：{{elevator.factoryNo}}</span>
          <span>{{$t("elevator.controlCabinetLeaveNo")}}：{{elevator.controlCabinetLeaveNo}}</span>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.useUnit")}}：{{elevator.useUnitName}}</span>
          <span>{{$t("elevator.manufactured")}}：{{elevator.createComName}}</span>
          <span>{{$t("elevator.property")}}：{{elevator.elevatorPropertyComName}}</span>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.useStatus")}}：
            <span v-if="elevator.useState === 1">{{$t("elevator.useStatus_1")}}</span>
            <span v-if="elevator.useState === 2">{{$t("elevator.useStatus_2")}}</span>
            <span v-if="elevator.useState === 3">{{$t("elevator.useStatus_3")}}</span>
            <span v-if="elevator.useState === 4">{{$t("elevator.useStatus_4")}}</span>
          </span>
          <span v-if="$i18n.isCn">{{$t("elevator.whereUsed")}}：{{elevator.applyPlaceName}}</span>
          <span v-else>{{$t("elevator.whereUsed")}}：{{elevator.applyPlaceNameEn}}</span>
          <span>{{$t("elevator.serviceDate")}}：{{elevator.useDate}}</span>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.installationEnterprise")}}：{{elevator.installComName}}</span>
          <span>{{$t("elevator.reconstructed")}}：{{elevator.remakeComName}}</span>
          <span></span>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.ratedSpeedOfElevator")}}：{{elevator.ratedSpeed}}</span>
          <span>{{$t("elevator.installationDate")}}：{{elevator.installDate}}</span>
          <span>{{$t("elevator.exFactoryDate")}}：{{elevator.factoryDate}}</span>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.ratedLoadOfElevator")}}：{{elevator.ratedLoad}}</span>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.maintenanced")}}：{{elevator.maintComName}}</span>
          <span>{{$t("elevator.maintWorker")}}：{{elevator.maintEmpName}}</span>
          <span>{{$t("elevator.maintWorker") + "2"}}：{{elevator.maintEmpName1}}</span>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.safetyOfficer")}}：{{elevator.safetyOfficerName}}</span>
          <span>{{$t("elevator.safetyDirector")}}：{{elevator.safetyOfficerName}}</span>
          <span>{{$t("elevator.safetyMainer")}}：{{elevator.safetyMainerName}}</span>
        </div>
        <div class="vm-separate">
          <span>项目名称：{{elevator.projectName}}</span>
          <span>{{$t("elevator.brand")}}：{{elevator.brand}}</span>
          <span>{{$l("elevator.yearMaint", "年度大保养时间")}}：{{elevator.yearMaintTime}}</span>
        </div>
        <el-divider content-position="left">位置信息</el-divider>
        <div class="vm-separate">
          <span>设备经纬度：{{
              elevator.longitude && elevator.latitude ? (elevator.longitude + ", " + elevator.latitude) :
                "未知"
            }}</span>
          <div></div>
          <span>{{$t("elevator.address")}}：{{elevator.address ? elevator.address : "未知"}}</span>
        </div>
        <div id="map" ref="map" style="height: 500px;width: 100%"></div>
      </el-tab-pane>
      <el-tab-pane :label="$t('elevator.iot')" name="second">
        <div class="vm-separate">
          <span>{{$t("elevator.joinStatus")}}：
            <span v-if="elevator.inNetState">{{$t("elevator.joined")}}</span>
            <span v-else>{{$t("elevator.notJoined")}}</span>
          </span>
          <span>{{$t("elevator.joinDate")}}：{{elevator.inNetDate}}</span>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.dtuModel")}}：{{elevator.deviceGateway.deviceModel}}</span>
          <span>{{$t("elevator.dtuCode")}}：{{elevator.dtuCode}}</span>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.simCardNo")}}：{{elevator.deviceGateway.simCode}}</span>
          <span>{{$l("elevator.iccid", "SIM卡ICCID")}}：{{elevator.deviceGateway.simICCID}}</span>
          <div></div>
        </div>
        <div class="vm-separate">
          <span>{{$t("elevator.faultTemplate")}}：{{elevator.faultName}}</span>
          <span>{{$t("elevator.accessPlatform")}}：{{integrationKeyCnList.toString()}}</span>
          <div></div>
        </div>
        <el-divider content-position="left">楼层对照</el-divider>
        <el-row>
          <el-table
            :data="elevator.elevatorFloors"
            max-height="300"
            border
            stripe
            style="width: 100%">
            <el-table-column prop="value" :label="$t('elevator.actualFloor')" width="150px">
              <template slot-scope="scope">
                {{scope.row.value}}
              </template>
            </el-table-column>
            <el-table-column prop="currentFloorCode" :label="$t('elevator.currentFloorCode')" width="200px">
              <template slot-scope="scope">
                {{scope.row.currentFloorCode}}
              </template>
            </el-table-column>
            <el-table-column prop="displayValue" :label="$t('elevator.displayedFloor')" width="150px">
              <template slot-scope="scope">
                {{scope.row.displayValue}}
              </template>
            </el-table-column>
            <el-table-column prop="stopStatus" :label="$t('elevator.stopStatus')" width="200px">
              <template slot-scope="scope">
                <span v-if="scope.row.stopStatus === 0">{{$l("common.no", "否")}}</span>
                <span v-if="scope.row.stopStatus === 1">{{$l("common.yes", "是")}}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-tab-pane>
      <el-tab-pane :label="$t('elevator.record')" name="fifth">
        <el-tabs v-model="innerActiveTab2" @tab-click="recordTabClick">
          <el-tab-pane v-if="workOrder" :label="$t('elevator.emergencyRecord')" name="a">
            <div>
              <vm-table-new
                ref="vmTableWorkOrder"
                :filter.sync="filter_workOrders"
                url="workOrders/web"
                @clear="clearWorkOrderDateRange">
                <template slot="adSearch">
                  <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
                    <el-input
                      v-model.trim="filter_workOrders.filter"
                      :placeholder="$t('common.search')"
                      style="width: 150px"
                      clearable></el-input>
                  </vm-search>
                  <vm-search :label="$t('workOrder.alarmType')">
                    <el-select
                      v-model="filter_workOrders.alarmType"
                      :placeholder="$t('common.pleaseSelect')"
                      style="width: 100%"
                      clearable>
                      <el-option
                        v-for="item in closedTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </vm-search>
                  <vm-search :label="$t('workOrder.alarmTime')">
                    <el-date-picker
                      v-model="workOrderDateRange"
                      value-format="yyyy-MM-dd"
                      type="daterange"
                      :range-separator="$t('common.to')"
                      :start-placeholder="$t('common.startDate')"
                      :end-placeholder="$t('common.endDate')"
                      style="width: 100%;"
                      @change="handleWorkOrderDateChange">
                    </el-date-picker>
                  </vm-search>
                </template>
                <el-table-column prop="workOrderNo" :label="$t('workOrder.workOrderNo')">
                  <template slot-scope="scope">
                    <router-link :to="'/workOrder/' + scope.row.id" target="_blank">
                      <span style="color: #409EFF">{{scope.row.workOrderNo}}</span>
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')"></el-table-column>
                <el-table-column prop="alarmTime" :label="$t('workOrder.alarmTime')"></el-table-column>
                <el-table-column :label="$t('workOrder.alarmType')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.alarmType === 0">
                      <el-tag type="">{{$t("workOrder.alarmType_0")}}</el-tag>
                    </div>
                    <div v-if="scope.row.alarmType === 1">
                      <el-tag type="warning">{{$t("workOrder.alarmType_1")}}</el-tag>
                    </div>
                    <div v-if="scope.row.alarmType === 2">
                      <el-tag type="danger">{{$t("workOrder.alarmType_2")}}</el-tag>
                    </div>
                    <div v-if="scope.row.alarmType === 3">
                      <el-tag type="danger">{{$t("workOrder.alarmType_3")}}</el-tag>
                    </div>
                  </template>
                </el-table-column>
              </vm-table-new>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="maintWorkOrder" :label="$t('elevator.maintenanceRecord')" name="b">
            <vm-table-new
              ref="maintTable"
              v-loading="maintLoading"
              :filter.sync="maintSearch"
              url="maintenanceOrder"
              @clear="clearMaintDateRange">
              <template slot="adSearch">
                <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
                  <el-input v-model.trim="maintSearch.filter" :placeholder="$t('common.search')" clearable></el-input>
                </vm-search>
                <vm-search :label="$l('maintWorkOrder.maintStatus', '状态')">
                  <el-select
                    v-model="maintSearch.maintStatus"
                    style="width:130px"
                    :placeholder="$t('common.pleaseSelect')"
                    clearable>
                    <el-option
                      v-for="item in stateOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </vm-search>
                <vm-search :label="$l('maintWorkOrder.completionStatus', '完成状态')">
                  <el-select
                    v-model="maintSearch.completionStatus"
                    style="width:130px"
                    :placeholder="$t('common.pleaseSelect')"
                    clearable>
                    <el-option
                      v-for="item in completionStateOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </vm-search>
                <vm-search :label="$l('maintWorkOrder.planDate', '计划保养日期')">
                  <el-date-picker
                    v-model="maintDateRange"
                    style="width:300px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    :unlink-panels="true"
                    :range-separator="$t('common.to')"
                    :start-placeholder="$t('common.startDate')"
                    :end-placeholder="$t('common.endDate')"
                    @change="handleMaintDateChange">
                  </el-date-picker>
                </vm-search>
              </template>
              <el-table-column prop="orderNum" :label="$l('maintWorkOrder.orderNum', '单号')" width="100px">
                <template v-slot="scope">
                  <router-link :to="'/newMaintWorkOrder/' + scope.row.id" target="_blank">
                    {{scope.row.orderNum}}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="elevatorNo" :label="$l('elevator.no', '电梯编号')" width="125px"></el-table-column>
              <el-table-column prop="elevatorName" :label="$l('maintWorkOrder.elevatorName', '电梯名称')"></el-table-column>
              <el-table-column prop="useUnitName" :label="$l('elevator.useUnit', '使用单位')"></el-table-column>
              <el-table-column prop="maintComName" :label="$l('maintWorkOrder.company', '维保单位')"></el-table-column>
              <el-table-column
                prop="propertyComName"
                :label="$l('maintWorkOrder.propertyCom', '物业单位')"></el-table-column>
              <el-table-column
                prop="maintDate"
                :label="$l('maintWorkOrder.maintDate', '保养日期')"
                width="90px"
                align="center"></el-table-column>
              <el-table-column
                prop="maintTypeName"
                :label="$l('maintWorkOrder.maintType', '保养类型')"
                :width="$l('90px', '70px')"
                align="center"></el-table-column>
              <el-table-column
                prop="completionTime"
                :label="$l('maintWorkOrder.completionTime', '完成时间')"
                width="140px"
                align="center"></el-table-column>
              <el-table-column
                prop="maintDuration"
                :label="$l('maintWorkOrder.maintDuration', '保养时长')"
                :width="$l('120px', '85px')"
                align="center">
                <template v-slot="scope">
                  <span v-if="scope.row.maintDuration !== null">
                    {{scope.row.maintDuration + $l(" minutes", " 分钟")}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="maintainerName1"
                :label="$l('elevator.maintWorker', '维保人员') + '1'"
                :width="$l('120px', '80px')"></el-table-column>
              <el-table-column
                prop="maintainerName2"
                :label="$l('elevator.maintWorker', '维保人员') + '2'"
                :width="$l('120px', '80px')"></el-table-column>
              <el-table-column
                prop="completionStatus"
                :label="$l('maintWorkOrder.completionStatus', '完成状态')"
                :width="$l('100px', '70px')"
                align="center">
                <template v-slot="scope">
                  <el-tag v-if="scope.row.completionStatus === 1" type="success">
                    {{$l("maintWorkOrder.normal", "正常")}}
                  </el-tag>
                  <el-tag v-else-if="scope.row.completionStatus === 2">
                    {{$l("maintWorkOrder.advance", "提前")}}
                  </el-tag>
                  <el-tag v-else-if="scope.row.completionStatus === 3" type="danger">
                    {{$l("maintWorkOrder.overdue", "超期")}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="maintStatus"
                :label="$l('maintWorkOrder.maintStatus', '状态')"
                :width="$l('120px', '70px')"
                align="center">
                <template v-slot="scope">
                  <el-tag v-if="scope.row.maintStatus === 0" type="danger">
                    {{$l("maintWorkOrder.invalid", "作废")}}
                  </el-tag>
                  <el-tag v-else-if="scope.row.maintStatus === 1" type="warning">
                    {{$l("maintWorkOrder.inPlan", "计划中")}}
                  </el-tag>
                  <el-tag v-else-if="scope.row.maintStatus === 2">
                    {{$l("maintWorkOrder.notAccepted", "未接受")}}
                  </el-tag>
                  <el-tag v-else-if="scope.row.maintStatus === 3">
                    {{$l("maintWorkOrder.accepted", "已接受")}}
                  </el-tag>
                  <el-tag v-else-if="scope.row.maintStatus === 4" type="info">
                    {{$l("maintWorkOrder.signedIn", "已签到")}}
                  </el-tag>
                  <el-tag v-else-if="scope.row.maintStatus === 5" type="success">
                    {{$l("maintWorkOrder.completed", "已完成")}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="creator"
                :label="$l('maintWorkOrder.creator', '建单人')"
                width="95px"
                align="center"></el-table-column>
            </vm-table-new>
          </el-tab-pane>
          <el-tab-pane v-if="yearCheckRecord" :label="$t('elevator.yearCheckRecord')" name="c">
            <div>
              <vm-table-new ref="vmTable1" v-loading="yearCheckLoading" :filter.sync="filter_yearChecks"
                            url="yearChecks">
                <template slot="adSearch">
                  <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
                    <el-input
                      v-model.trim="filter_yearChecks.filter"
                      :placeholder="$t('common.search')"
                      clearable></el-input>
                  </vm-search>
                </template>
                <el-table-column prop="yearCheckNo" :label="$t('yearCheck.yearCheckNo')"></el-table-column>
                <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')"></el-table-column>
                <el-table-column prop="maintenanceComName" :label="$t('elevator.maintenanced')"></el-table-column>
                <el-table-column prop="checkDate" :label="$t('yearCheck.checkDate')" width="155px"></el-table-column>
                <el-table-column prop="status" :label="$t('yearCheck.status')">
                  <template slot-scope="scope">
                    <el-tag :type="scope.row.status === 0 ? 'info' : 'success'">
                      {{scope.row.status === 0 ? $t("yearCheck.result.noPass") : $t("yearCheck.result.pass")}}
                    </el-tag>
                  </template>
                </el-table-column>
              </vm-table-new>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="faultRecord" :label="$t('elevator.faultRecord')" name="e">
            <div>
              <vm-table-new ref="vmTable" :filter.sync="filter_faultRecords" url="faultRecords" @clear="clearDateRange">
                <template slot="adSearch">
                  <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
                    <el-input
                      v-model.trim="filter_faultRecords.filter"
                      :placeholder="$t('common.search')"
                      clearable></el-input>
                  </vm-search>
                  <vm-search :label="$l('workOrder.faultTime', '故障时间')">
                    <el-date-picker
                      v-model="dateRange"
                      value-format="yyyy-MM-dd"
                      type="daterange"
                      :range-separator="$t('common.to')"
                      :start-placeholder="$t('common.startDate')"
                      :end-placeholder="$t('common.endDate')"
                      :default-time="['00:00:00', '23:59:59']"
                      @change="handleDateChange">
                    </el-date-picker>
                  </vm-search>
                </template>
                <el-table-column prop="gbFaultCode" label="国标/地标映射" width="120px"></el-table-column>
                <el-table-column prop="faultCode" label="原始故障码" width="120px"></el-table-column>
                <el-table-column prop="elevatorNo" :label="$t('elevator.no')" width="135px"></el-table-column>
                <el-table-column prop="elevatorName" :label="$t('elevator.name')"></el-table-column>
                <el-table-column prop="faultDesc" :label="$t('workOrder.faultType')" width="180px"></el-table-column>
                <el-table-column prop="faultTime" :label="$t('workOrder.faultTime')" width="155px"></el-table-column>
                <el-table-column
                  v-if="false"
                  prop="sendTime"
                  :label="$t('workOrder.mainBoardTime')"
                  width="155px"></el-table-column>
                <el-table-column v-if="false" prop="faultFloor" :label="$t('workOrder.faultStorey')"></el-table-column>
                <el-table-column
                  prop="faultFloor"
                  :label="$t('workOrder.displayFaultFloor')"
                  width="110px"
                  align="center"></el-table-column>
              </vm-table-new>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="fileRecord" label="自保记录" name="f">
            <div>
              <vm-table-new ref="fileTable" :filter.sync="filter_fileRecords" url="files/filePage">
                <template slot="adSearch">
                  <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
                    <el-input
                      v-model.trim="filter_fileRecords.filter"
                      :placeholder="$t('common.search')"
                      clearable></el-input>
                  </vm-search>
                </template>
                <el-upload
                  slot="toolbar"
                  :action="uploadUrl"
                  :headers="uploadHeaders"
                  :before-upload="beforeUpload"
                  :on-error="handlerError"
                  :on-success="handleSuccess"
                  :data="filter_fileRecords"
                  :multiple="true"
                  :show-file-list="false"
                  accept=".png, .jpeg, .jpg, .doc, .pdf, .docx, .xlsx">
                  <el-button :loading="uploadLoading" icon="el-icon-upload2" size="mini" type="primary">
                    {{$l("programCard.upload", "上传")}}
                  </el-button>
                </el-upload>
                <el-table-column prop="name" label="名称">
                  <template v-if="scope.row.name && scope.row.path" slot-scope="scope">
                    <el-link type="primary" @click="downloadFile(scope.row.path, scope.row.name)">
                      {{scope.row.name}}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="uploadTime" label="上传时间"></el-table-column>
                <el-table-column prop="creator" label="创建人"></el-table-column>
                <el-table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" width="120px">
                  <template slot-scope="scope">
                    <el-button type="danger" @click="handleDelete(scope.row)">
                      {{$l("elevatorNetApply.delete", "删除")}}
                    </el-button>
                  </template>
                </el-table-column>
              </vm-table-new>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="otherRecord" label="其他记录" name="h">
            <div>
              <vm-table-new ref="otherTable" :filter.sync="filter_otherRecords" url="files/filePage">
                <template slot="adSearch">
                  <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
                    <el-input
                      v-model.trim="filter_otherRecords.filter"
                      :placeholder="$t('common.search')"
                      clearable></el-input>
                  </vm-search>
                </template>
                <el-upload
                  slot="toolbar"
                  :action="uploadUrl"
                  :headers="uploadHeaders"
                  :before-upload="beforeUpload"
                  :on-error="handlerError"
                  :on-success="handleSuccess"
                  :data="filter_otherRecords"
                  :multiple="true"
                  :show-file-list="false"
                  accept=".png, .jpeg, .jpg, .doc, .pdf, .docx, .xlsx">
                  <el-button :loading="uploadLoading" icon="el-icon-upload2" size="mini" type="primary">
                    {{$l("programCard.upload", "上传")}}
                  </el-button>
                </el-upload>
                <el-table-column prop="name" label="名称">
                  <template v-if="scope.row.name && scope.row.path" slot-scope="scope">
                    <el-link type="primary" @click="downloadFile(scope.row.path, scope.row.name)">
                      {{scope.row.name}}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column prop="uploadTime" label="上传时间"></el-table-column>
                <el-table-column prop="creator" label="创建人"></el-table-column>
                <el-table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" width="120px">
                  <template slot-scope="scope">
                    <el-button type="danger" @click="handleDelete(scope.row)">
                      {{$l("elevatorNetApply.delete", "删除")}}
                    </el-button>
                  </template>
                </el-table-column>
              </vm-table-new>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import {stopStatus} from "../../util/constant";
  import VmTableNew from "../../components/refactor/VmTable";
  import auth from "@/util/auth";

  const moduleName = "elevators";
  const moduleName_productType = "elevatorProductTypes";
  export default {
    components: {VmTableNew},
    data() {
      return {
        yearCheckRecord: this.$auth(132),
        faultRecord: this.$auth(117),
        workOrder: this.$auth(302),
        maintWorkOrder: this.$auth(322),
        fileRecord: this.$auth(824),
        otherRecord: this.$auth(825),
        siteName: window.config.siteName,
        maintLoading: false,
        uploadLoading: false,
        maintDateRange: [],
        maintSearch: {
          elevatorId: this.$route.params.elevatorId,
          filter: "",
          completionStatus: "",
          maintStatus: "",
          startTime: "",
          endTime: "",
        },
        stateOptions: [
          {value: "0", label: this.$l("maintWorkOrder.invalid", "作废")},
          {value: "1", label: this.$l("maintWorkOrder.inPlan", "计划中")},
          {value: "2", label: this.$l("maintWorkOrder.notAccepted", "未接受")},
          {value: "3", label: this.$l("maintWorkOrder.accepted", "已接受")},
          {value: "4", label: this.$l("maintWorkOrder.signedIn", "已签到")},
          {value: "5", label: this.$l("maintWorkOrder.completed", "已完成")}],
        completionStateOptions: [
          {value: "1", label: this.$l("maintWorkOrder.normal", "正常")},
          {value: "2", label: this.$l("maintWorkOrder.advance", "提前")},
          {value: "3", label: this.$l("maintWorkOrder.overdue", "超期")}],
        activeTab: "first",
        innerActiveTab1: "one",
        innerActiveTab2: "a",
        InnerShowCondition: "",
        productTypeList: [],
        yearCheckLoading: false,
        group: [],
        listData: [],
        map: null,
        closedTypes: [
          {
            value: 0,
            label: this.$t("workOrder.alarmType_0"),
          },
          {
            value: 1,
            label: this.$t("workOrder.alarmType_1"),
          },
          {
            value: 2,
            label: this.$t("workOrder.alarmType_2"),
          },
          {
            value: 3,
            label: this.$t("workOrder.alarmType_3"),
          }],
        dateRange: [],
        filter_faultRecords: {
          filter: "",
          startTime: "",
          endTime: "",
          elevatorId: this.$route.params.elevatorId,
        },
        uploadUrl: window.config.SERVER_URL + "files/upload/elevatorArchives",
        uploadHeaders: {Authorization: auth.getToken()},
        filter_fileRecords: {
          filter: "",
          module: "elevatorArchives",
          relatedId: this.$route.params.elevatorId,
          relatedType: "1",
          fileName: "",
          isImage: false,
        },
        filter_otherRecords: {
          filter: "",
          module: "elevatorArchives",
          relatedId: this.$route.params.elevatorId,
          relatedType: "2",
          fileName: "",
          isImage: false,
        },
        recordTabName: "",
        workOrderDateRange: [],
        filter_workOrders: {
          alarmType: "",
          filter: "",
          startTime: "",
          endTime: "",
          elevatorId: this.$route.params.elevatorId,
        },
        filter_yearChecks: {
          filter: "",
          elevatorId: this.$route.params.elevatorId,
        },
        tableData_faultRecords: [],
        integrationKeyCnList: [],
        elevator: {
          id: 0,
          no: "",
          mac: "",
          name: "",
          productTypeId: "",
          integrationKeys: [],
          controlCabinetLeaveNo: "",
          elevatorProductTypeName: "",
          elevatorProductTypeNameEn: "",
          brand: "",
          maintType: 0,
          createComId: 0,
          createComName: "",
          model: "",
          address: "",
          deviceId: "",
          regNo: "",
          factoryNo: "",
          factoryDate: "",
          installDate: "",
          applyPlaceName: "",
          useUnitId: 0,
          useUnitName: "",
          maintEmpId: 0,
          maintEmpName: "",
          inNetState: false,
          useState: 1,
          inNetDate: "",
          useDate: "",
          quantityOrganId: 0,
          quantityOrganName: "",
          installComId: 0,
          installComName: "",
          remakeComId: "",
          remakeComName: "",
          maintComId: 0,
          maintComName: "",
          projectName: "",
          faultId: 0,
          faultName: "",
          dtuCode: "",
          iccid: "",
          nodeCode: "",
          highestFloor: 0,
          ratedLoad: 0,
          ratedSpeed: 0,
          rise: 0,
          angle: 0,
          width: 0,
          length: 0,
          deviceModel: "",
          floorDisplayId: 0,
          floorDisplayName: "",
          elevatorFloors: [],
          deviceGateway: {deviceModel: "", simCode: "", simExpireDate: "", simICCID: ""},
          safetyOfficerName: "",
          safetyDirectorName: "",
          safetyMainerName: "",
          isAutoReport: false,
        },
        integrationList: [],
        searchValue: "",
        filterText: "",
        loadingTree: false,
        data: [
          {
            id: 0,
            name: "全部",
            parentID: 0,
            children: [],
          },
        ],
        defaultProps: {
          children: "children",
          label: "name",
        },
        changePart: null,
        partsTypeId: 0,
        loading1: false,
        currentPage1: 1,
        total1: 0,
        tableData1: [],
        loading2: false,
        currentPage2: 1,
        total2: 0,
        tableData2: [],
        elevatorParts: {
          id: 0,
          elevatorID: 0,
          partsID: 0,
          partsName: "",
        },
        elevatorPartsSearch: {
          elevatorId: 0,
          partsTypeId: 0,
        },
        elevatorPartsReplacementsSearch: {
          elevatorId: 0,
          partsTypeId: 0,
        },
      };
    },
    mounted() {
      this.elevator.id = this.$route.params.elevatorId;
      this.getData();
      this.getIntegrationList();
      this.getProductTypeList();
    },
    methods: {
      initMap(longitude, latitude) {
        this.map = new window.BMap.Map(this.$refs.map);
        if (longitude && latitude) {
          this.point = new window.BMap.Point(longitude, latitude);
          this.addPoint(longitude, latitude);
          this.map.centerAndZoom(this.point, 16);// 创建中心点以及地图放大级别
        } else {
          this.point = new window.BMap.Point(109.74484, 34.80992);
          this.map.centerAndZoom(this.point, 6);
        }
        this.map.enableScrollWheelZoom(true);// 鼠标缩放
      },
      addPoint(longitude, latitude) {
        const point = new window.BMap.Point(longitude, latitude);
        const marker = new window.BMap.Marker(point); // 创建标注
        marker.customData = {type: "point"};
        this.map.addOverlay(marker);// 将标注添加到地图中
        const opts = {
          width: 250,
          height: 80,
          title: "位置信息",
        };
        const infoWindow = new window.BMap.InfoWindow("电梯名称：" + this.elevator.name + "<br/>" + "详细地址：" + this.elevator.address, opts);
        // 点标记添加点击事件
        marker.addEventListener("click", function() {
          this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
        });
      },
      getData() {
        this.$api.getById("elevators", this.elevator.id).then(res => {
          this.elevator = Object.assign(this.elevator, res.data);
          if (this.elevator.integrationKeys) {
            this.elevator.integrationKeys.forEach(item => {
              let list = this.integrationList.filter(integration => integration.integrationKey === item);
              this.integrationKeyCnList.push(list[0].name);
            });
          }
          this.handleProductTypeSelectChange();
          this.initMap(this.elevator.longitude, this.elevator.latitude);
          if (this.workOrder) {
            this.getList_workOrder(1);
          }
          if (this.yearCheckRecord) {
            this.getList_yearChecks(1);
          }
          if (this.faultRecord) {
            this.getList_faultRecords(1);
          }
          if (this.fileRecord) {
            this.getList_fileRecords(1);
          }
          if (this.otherRecord) {
            this.getList_otherRecords(1);
          }
          if (this.maintWorkOrder) {
            this.getList_maintWorkOrder(1);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + error.response.data.message);
          }
        });
      },
      getIntegrationList() {
        this.$http.get("integrations/list").then(({data}) => {
          this.integrationList = data;
        });
      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.filter_faultRecords.startTime = dateRange[0];
          this.filter_faultRecords.endTime = dateRange[1];
        } else {
          this.filter_faultRecords.startTime = "";
          this.filter_faultRecords.endTime = "";
        }
      },
      handleWorkOrderDateChange(dateRange) {
        if (dateRange != null) {
          this.filter_workOrders.startTime = dateRange[0];
          this.filter_workOrders.endTime = dateRange[1];
        } else {
          this.filter_workOrders.startTime = "";
          this.filter_workOrders.endTime = "";
        }
      },
      clearDateRange() {
        this.dateRange = [];
      },
      clearWorkOrderDateRange() {
        this.workOrderDateRange = [];
      },
      clearMaintDateRange() {
        this.maintDateRange = [];
      },
      handleMaintDateChange(maintDateRange) {
        if (maintDateRange != null) {
          this.maintSearch.startTime = maintDateRange[0];
          this.maintSearch.endTime = maintDateRange[1];
        } else {
          this.maintSearch.startTime = "";
          this.maintSearch.endTime = "";
        }
      },
      handleProductTypeSelectChange() {
        for (let productTypeItem of this.productTypeList) {
          if (productTypeItem.value === this.elevator.productTypeId) {
            if (productTypeItem.label === "自动扶梯") {
              this.InnerShowCondition = "自动扶梯";
            } else if (productTypeItem.label === "自动人行道") {
              this.InnerShowCondition = "自动人行道";
            } else {
              this.InnerShowCondition = "其他";
            }
          }
        }
      },
      getProductTypeList() {
        this.$api.getList(moduleName_productType).then(response => {
          this.productTypeList = [];
          for (let productType of response.data) {
            let item = {value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName};
            this.productTypeList.push(item);
          }
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      initGroup() {
        for (let dataItem of this.listData) {
          this.setGroup(dataItem.type);
        }
      },
      setGroup(type) {
        let result = false;
        for (let groupItem of this.group) {
          if (type === groupItem) {
            result = true;
          }
        }
        if (!result) {
          this.group.push(type);
        }
      },
      getList_workOrder(pageIndex) {
        this.$refs.vmTableWorkOrder.getList(pageIndex);
      },
      getList_yearChecks(pageIndex) {
        this.$refs.vmTable1.getList(pageIndex);
      },
      getList_maintWorkOrder(pageIndex) {
        this.maintLoading = true;
        this.$refs.maintTable.getList(pageIndex);
        this.maintLoading = false;
      },
      getList_faultRecords(pageIndex) {
        this.$refs.vmTable.getList(pageIndex);
      },
      getList_otherRecords(pageIndex) {
        this.$refs.otherTable.getList(pageIndex);
      },
      getList_fileRecords(pageIndex) {
        this.$refs.fileTable.getList(pageIndex);
      },
      downloadFile(url, name) {
        fetch(url).then(response => response.blob()).then(result => {
          let objectUrl = window.URL.createObjectURL(result);
          let link = document.createElement("a");
          link.href = objectUrl;
          link.setAttribute("download", name);
          link.click();
          link.remove();
        });
      },
      beforeUpload(file) {
        let fileName = file.name;
        let pos = fileName.lastIndexOf(".");
        let lastName = fileName.substring(pos, fileName.length);
        let size = file.size / 1024 / 1024;
        if (lastName.toLowerCase() === ".png" ||
          lastName.toLowerCase() === ".jpeg" ||
          lastName.toLowerCase() === ".jpg") {
          if (size > 3) {
            this.$message.error(this.$l("programCard.imageFiles", "图片文件不能超过 3MB!"));
            return false; // 返回false中止上传
          } else {
            if (this.recordTabName === "f") {
              this.filter_fileRecords.fileName = fileName;
              this.filter_fileRecords.isImage = true;
            }
            if (this.recordTabName === "h") {
              this.filter_otherRecords.fileName = fileName;
              this.filter_otherRecords.isImage = true;
            }
          }
        }
        if (lastName.toLowerCase() === ".doc" ||
          lastName.toLowerCase() === ".docx" ||
          lastName.toLowerCase() === ".pdf" ||
          lastName.toLowerCase() === ".xlsx") {
          if (size > 5) {
            this.$message.error(this.$l("programCard.videoFiles", "文档文件不能超过 5MB!"));
            return false; // 返回false中止上传
          } else {
            if (this.recordTabName === "f") {
              this.filter_fileRecords.fileName = fileName;
              this.filter_fileRecords.isImage = true;
            }
            if (this.recordTabName === "h") {
              this.filter_otherRecords.fileName = fileName;
              this.filter_otherRecords.isImage = true;
            }
          }
        }
        if (lastName.toLowerCase() !== ".png" &&
          lastName.toLowerCase() !== ".jpeg" &&
          lastName.toLowerCase() !== ".jpg" &&
          lastName.toLowerCase() === ".doc" &&
          lastName.toLowerCase() === ".docx" &&
          lastName.toLowerCase() === ".pdf" &&
          lastName.toLowerCase() === ".xlsx"
        ) {
          this.$message.error(this.$l("programCard.fileType", "请上传允许的文件类型"));
          return false; // 返回false中止上传
        }
      },
      handleSuccess(res) {
        if (this.recordTabName === "f") {
          this.getList_fileRecords(1);
        }
        if (this.recordTabName === "h") {
          this.getList_otherRecords(1);
        }
        this.uploadLoading = false;
        this.$message.success(this.$l("programCard.uploadSuccessful", "上传成功"));
      },
      handlerError(err) {
        this.$message.error("上传失败");
        this.uploadLoading = false;
      },
      handleDelete(row) {
        this.$confirm("确定删除吗?", this.$t("common.tips"), {type: "warning"}).then(() => {
          this.$http.delete("files", row.id).then(() => {
            if (this.recordTabName === "f") {
              this.getList_fileRecords(1);
            }
            if (this.recordTabName === "h") {
              this.getList_otherRecords(1);
            }
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          });
        });
      },
      getStopStatus(type) {
        return stopStatus[type];
      },
      compareTodayDate(aaa) {
        let date = new Date();
        let month = date.getMonth() + 1;
        let strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        this.today = date.getFullYear() + "-" + month + "-" + strDate;
        return new Date(aaa) < new Date(this.today);
      },
      filterNode(value, data, node) {
        if (!value) {
          return true;
        }
        if (node.parent.parent !== null && node.parent.visible) {
          return true;
        }
        return node.label.indexOf(value) !== -1;
      },
      getTreeData() {
        this.loadingTree = true;
        this.$axios.get("partsTypes/tree").then((res) => {
          this.data[0].children = res.data;
          this.loadingTree = false;
        });
      },
      getList1(pageIndex) {
        this.elevatorPartsSearch.elevatorId = this.elevator.id;
        this.elevatorPartsSearch.partsTypeId = this.partsTypeId;
        this.$refs.elevatorPartsTable.getList(pageIndex);
      },
      getList2(pageIndex) {
        this.elevatorPartsReplacementsSearch.elevatorId = this.elevator.id;
        this.elevatorPartsReplacementsSearch.partsTypeId = this.partsTypeId;
        this.$refs.vmTable2.getList(pageIndex);
      },
      tree(data) {
        this.partsTypeId = data.id;
        this.refreshList(1);
      },
      refreshList(pageIndex) {
        // 两个请求现在都执行完成
        this.$axios.all([this.getList1(pageIndex), this.getList2(1)]).then(this.$axios.spread(function(acct, perms) {
        }));
      },
      tabClick(tab, event) {
        if (tab.name === "seventh") {
          this.partsTypeId = 0;
          this.defaultProps = "";
          this.getList1(1);
          this.getList2(1);
          this.getTreeData();
        }
      },
      recordTabClick(tab, event) {
        console.log(tab.name);
        this.recordTabName = tab.name;
      },
    },
  };
</script>

<style lang="scss" scoped>
.body {
  margin: 10px 40px 10px 40px;
  padding: 20px 60px 20px 60px;
  border: solid 1px #DCDFE6;

  span {
    color: #606266;
  }

  .vm-separate {
    font-size: 14px;
    margin-bottom: 15px;
  }

  ::v-deep .el-divider--horizontal {
    margin: 30px 0 30px 0;
  }

  .companys {
    font-size: 14px;
    margin-bottom: 20px;
    border: solid 1px #5AA1FE;
    border-radius: 10px;
    background-color: #F3F8FF;
  }

  .companys span {
    margin: 20px;
    line-height: 40px;
  }

  .params span {
    margin: 20px;
    line-height: 40px;
    font-size: 14px;
  }

  td {
    width: 25%;
  }

  li {
    list-style-type: none;
  }

  .groupItem {
    float: left;
  }
}
</style>
